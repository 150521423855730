<template>

<spinner v-if="load" />

  <b-card-code v-else>
 <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.profile_photo_url"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ statusVariantText(props.row.status) }}
          </b-badge>
        </span>

         <span v-else-if="props.column.field === 'created_at'">
          {{props.row.created_at |  moment("from", "now")}}
        </span>

       

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          @click="redirect(props.row.hash)"
        >
          View
        </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
  </b-card-code>
 
</template>

<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge,BButton,  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import useServices from "@/auth/jwt/userServices"
import Spinner from '../components/Spinner.vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
     BCardCode,
     Spinner,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
       mainurl: jwtDefaultConfig.mainurl,
      load: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Username',
          field: 'username',
        },
        {
          label: 'Email',
          field: 'email',
        },
       
       
         {
          label: 'Date',
          field: 'created_at',
        },
        
      ],
      rows: [],
      searchTerm: '',
      status: [{
       
        2: 'Completed',
        3: 'Pending Payment',
        4: 'Processing',
        5: 'Cancelled',
      },
      {
     
        2: 'light-success',
         3: 'light-warning',
        4: 'light-info',
        5: 'light-danger',
       
       
      }],
    }
  },
  computed: {



     





       statusVariantText() {
      const statusColor = {
        /* eslint-disable key-spacing */
   
        3 : 'Completed',
       0    : 'Cancelled',
        1    : 'Pending payment',
        2     : 'Processing',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
      },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
       
        3 : 'light-success',
        0    : 'light-danger',
        1    : 'light-warning',
        2     : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
   this.getreferrals();
  },
  methods: {
    redirect(id) {

         this.$router.push({name: "transaction", params: { hash: id}});

      },

     async getreferrals()    {

           try {
               
           this.load = true;
            await useServices.getreferrals().then((result)=> {
               if (result.data.status != false) {
                console.log(result.data.data)
               this.rows = result.data.data;
         
            }
               
            });

           

            this.load = false;

           } catch (error) {
                this.load = false;
            //   this.$router.back();

           }

        },
  },
}
</script>
 
  <style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
